
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { supportedLngs } from "../../resource/lngs"

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: supportedLngs,
    fallbackLng: supportedLngs,
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },

    //defaultNS: "fr/translation.json",

    backend: {
      loadPath: '../../../assets/locales/{{lng}}/translation.json',
    },

    interpolation: {
      escapeValue: false
    }
  })