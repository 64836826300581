import React, { useEffect } from "react";
import "./LoadingIcon.scss"

function shuffle(array) {
  var currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
  return array;
}

export default function LoadingIcon(props) {
  useEffect(() => {
    props.setIsLoading(true);
    const paths = document.querySelectorAll("path");
    shuffle(Array.from(paths)).forEach((p) => {
      setTimeout(() => {
        p.classList.add(p.dataset.classname);
      }, 500 + Math.random() * 1000)
    })
    setTimeout(() => {
      props.setIsLoading(false);
    }, 4000)
  }, [])

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.61 35.93">
      <title>Asset 1</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_2-2" data-name="Layer 2">
          <path d="M15.91,32.15v0h0v0Z" />
          <path d="M24.45,15.05h0l0,0h0Z" />
          <path d="M9.41,22.71h0Z" />
          <path d="M14.5,15.05v0h0l0,0Z" />
          <path data-classname="cls-1" d="M30.11,5.33a1.36,1.36,0,0,0,.06.29,12.37,12.37,0,0,0,.61,2.54.63.63,0,0,0,.36-.27c-.06-.25-.13-.49-.21-.73A9.45,9.45,0,0,0,30.11,5.33Z" />
          <path data-classname="cls-2" d="M25.94,2.36c-.06.17-.22.47-.18.5,1.34,1,.33,1.38-.42,1.87h0c0,.49,0,1,0,1.47.84-.21,1.76-.32,1.81-1.5a.32.32,0,0,1,.38-.33c.48.14.42.58.47,1l.87-.3a1.33,1.33,0,0,1-.18-1.59A9.08,9.08,0,0,0,27.2,2.2,1.34,1.34,0,0,1,25.94,2.36Z" />
          <path data-classname="cls-3" d="M28.88,5h0l.57-.31h0a2,2,0,0,0,0-.35,8.34,8.34,0,0,0-.77-.92h0A1.33,1.33,0,0,0,28.88,5Z" />
          <path data-classname="cls-4" d="M29.46,4.72h0l0,0h.24c-.08-.13-.16-.25-.25-.37a2,2,0,0,1,0,.35Z" />
          <path data-classname="cls-5" d="M31.24,13l-.3.58c-.74.52-1.28,1-.05,1.62a.65.65,0,0,1,.21.16,13.2,13.2,0,0,0,.46-2.46C31.41,12.87,31.29,12.88,31.24,13Z" />
          <polygon data-classname="cls-6" points="28 9.45 28 9.45 28 9.45 28 9.45" />
          <path data-classname="cls-6" d="M29.73,7.14a15.74,15.74,0,0,0-.25-2.4h0l0,0h0L28.89,5c0,.19,0,.39,0,.58h0a2.25,2.25,0,0,1-.55,2.14c-.38.42-.86,1-.33,1.7a1.94,1.94,0,0,1,.59-1.18c1-.07,1.24.72,1.47,1.47l.29.3h0a2,2,0,0,1,1.24.65,13.36,13.36,0,0,0-.17-1.55A1.76,1.76,0,0,1,29.73,7.14Z" />
          <polygon data-classname="cls-6" points="28.89 5.03 28.89 5.03 28.89 5.03 28.89 5.03" />
          <path data-classname="cls-7" d="M30.78,8.16a12.37,12.37,0,0,1-.61-2.54,1.36,1.36,0,0,1-.06-.29,5.36,5.36,0,0,0-.38-.59h-.25a15.74,15.74,0,0,1,.25,2.4,1.76,1.76,0,0,0,1.69,2c-.07-.42-.17-.84-.28-1.25A.63.63,0,0,1,30.78,8.16Z" />
          <path data-classname="cls-8" d="M30.35,10c.11.36.23.72.32,1.09.29,1.06,0,1.59-1.19,1.28h0c-.27.94,0,2.23-1.49,2.36h0v.87h0l1.19-.58c.47-.63.27-2.07,1.76-1.48l.3-.58a1.15,1.15,0,0,1,.37-1.09v-1a1.64,1.64,0,0,0,0-.23A2,2,0,0,0,30.35,10Z" />
          <path data-classname="cls-9" d="M31.24,13c.05-.11.17-.12.32-.1,0-.23,0-.45.05-.68V11.9A1.15,1.15,0,0,0,31.24,13Z" />
          <path data-classname="cls-10" d="M24.75,2.05h0c-.59.59.85,1.73-.58,2.08a1.23,1.23,0,0,1-.29.9h0c0,.1,0,.2,0,.3h0c0,.48,0,1,0,1.45h0c.65.25,1,0,1.14-.66.07-.47,0-1,.32-1.39h0c-.1-.6.28-1.18,0-1.81-.08-.16-.43-1.22.64-.85h0a1.43,1.43,0,0,0,.8-.17,11.47,11.47,0,0,0-1.92-1L24.66.88l.09,1.17Z" />
          <path data-classname="cls-11" d="M25.63,7.09h0a2.81,2.81,0,0,1,0,.29h0c1.46,1,1.68-.55,2.36-1.17h0V5.33h0c-.05-.38,0-.82-.47-1a.32.32,0,0,0-.38.33c-.05,1.18-1,1.29-1.81,1.5C25.44,6.5,25.54,6.8,25.63,7.09Z" />
          <path data-classname="cls-12" d="M25,10.34c-.44-1.2-.71-2.37.59-3.25h0c-.09-.29-.19-.59-.28-.89,0-.49,0-1,0-1.47h0c-.36.41-.25.92-.32,1.39-.1.69-.49.91-1.14.66h0a4.7,4.7,0,0,1-.61,3h0l.6.59A1.74,1.74,0,0,1,25,10.34Z" />
          <path data-classname="cls-12" d="M25.76,2.86s.12-.33.18-.5V2.07h0c-1.07-.37-.72.69-.64.85.32.63-.06,1.21,0,1.81C26.09,4.24,27.1,3.82,25.76,2.86Z" />
          <path data-classname="cls-13" d="M25.94,2.07v.29A1.34,1.34,0,0,0,27.2,2.2a4.53,4.53,0,0,0-.46-.3,1.43,1.43,0,0,1-.8.17Z" />
          <path data-classname="cls-3" d="M28.89,5h0l-.87.3h0v.88c.27-.23.48-.55.87-.6h0c0-.19,0-.39,0-.58Z" />
          <path data-classname="cls-14" d="M30.89,15.19c-1.23-.61-.69-1.1.05-1.62-1.49-.59-1.29.85-1.76,1.48l-.59,1.18c.78.26.53.93.58,1.47h0a1.55,1.55,0,0,0,.72.37A11.56,11.56,0,0,0,30.5,17a2.44,2.44,0,0,0-.81,0c.07-.6.82-.92.5-1.57.22.23.51.41.7.62,0-.07.05-.12.07-.18a4.67,4.67,0,0,0,.14-.5A.65.65,0,0,0,30.89,15.19Z" />
          <path data-classname="cls-15" d="M28,6.21h0a8.7,8.7,0,0,1-.74,2c-.31.54-.64.41-1,.36L25.64,10c1.17.12,2.42-.6,3.53.29.3-.19.29-.85.89-.59-.23-.75-.43-1.54-1.47-1.47.28.63-.15.91-.59,1.18h0c-.53-.69-.05-1.28.33-1.7a2.25,2.25,0,0,0,.55-2.14h0C28.49,5.66,28.28,6,28,6.21Z" />
          <path data-classname="cls-16" d="M29.17,10.33h0l.3,2.07h0c1.19.31,1.48-.22,1.19-1.28-.09-.37-.21-.73-.32-1.09h0l-.29-.3C29.46,9.48,29.47,10.14,29.17,10.33Z" />
          <path data-classname="cls-17" d="M28,9.45h0c.44-.27.87-.55.59-1.18A1.94,1.94,0,0,0,28,9.45Z" />
          <path data-classname="cls-18" d="M27.36,15.1c-.73.84-1.08.28-1.44-.35l-.57,1.19h0c.49,1,.71,1.83-.77,2.12-.75.15-.69.83-.72,1.41h0c.25.51.36,1.1,1.07,1.24s.69.61.7,1.12h0l.59-.29h0l-.58-1.18c-.71-.93-.47-2,.63-2,1.31,0,.9-.61.84-1.24h0c0-.64.14-1.25.87-1.48h0v-.87A2,2,0,0,0,27.36,15.1Z" />
          <path data-classname="cls-16" d="M29.47,12.4h0c-.5-.08-1,.35-1.48.06h0l-.55.27h0c-.9.38-1.66.86-1.52,2h0c.36.63.71,1.19,1.44.35a2,2,0,0,1,.63-.34h0c1.53-.13,1.22-1.42,1.49-2.36h0Z" />
          <polygon data-classname="cls-19" points="27.98 15.63 27.98 15.63 27.98 15.63 27.98 15.63" />
          <path data-classname="cls-19" d="M27.7,18c-.12.3-.12.54.29.58l.6-.58a1.53,1.53,0,0,1,0-1.77h0l.59-1.18L28,15.63h0C27.93,16.42,28.2,17.26,27.7,18Z" />
          <path data-classname="cls-20" d="M21.51.58c.86-.25,1.55-.35,1.23,1a8.54,8.54,0,0,0,0,1.92c.43-.54.87-.85,1,0A10.55,10.55,0,0,1,23.88,5h0a1.23,1.23,0,0,0,.29-.9c0-.73-.21-1.55.58-2.08h0L24.66.88a13.9,13.9,0,0,0-3.3-.75,1.1,1.1,0,0,0-.14.18Z" />
          <path data-classname="cls-21" d="M23.88,5h0a10.55,10.55,0,0,0-.13-1.59c-.17-.82-.61-.51-1,0a8.54,8.54,0,0,1,0-1.92c.32-1.32-.37-1.22-1.23-1l-.3.9h0c.75.25.92.89,1.05,1.56A3.94,3.94,0,0,0,23,4.72h0c.2.3.4.6.59.9h0l.27-.29h0C23.85,5.23,23.87,5.13,23.88,5Z" />
          <path data-classname="cls-22" d="M23.27,9.74a4.7,4.7,0,0,0,.61-3h0c0-.48,0-1,0-1.45h0l-.27.29h0c-.05.92.21,1.9-.59,2.65l-.3.29-.32.54.18.17a3.84,3.84,0,0,1,.41.56A.8.8,0,0,1,23.27,9.74Z" />
          <path data-classname="cls-23" d="M24.75,2.05h0c-.79.53-.54,1.35-.58,2.08C25.6,3.78,24.16,2.64,24.75,2.05Z" />
          <path data-classname="cls-24" d="M25.64,7.38h0a2.81,2.81,0,0,0,0-.29h0c-1.3.88-1,2.05-.59,3.25h0l.6-.3h0l.58-1.48C24.71,8.82,26,7.7,25.64,7.38Z" />
          <path data-classname="cls-25" d="M25.64,7.38h0c.34.32-.93,1.44.58,1.18.4,0,.73.18,1-.36a8.7,8.7,0,0,0,.74-2C27.32,6.83,27.1,8.34,25.64,7.38Z" />
          <path data-classname="cls-26" d="M25,14.75h0c.1.39.21.79.31,1.19h0l.57-1.19h0c-.14-1.16.62-1.64,1.52-2h0c-.53-.69.31-1.19.26-1.81h0c-1.07.65-1.84-.09-2.66-.58h0a1.74,1.74,0,0,0-1.17,0c-.83.78-.19,1.56,0,2.34h.29C25.25,13,25.29,13.09,25,14.75Z" />
          <path data-classname="cls-27" d="M23.87,12.67h0c-.19-.78-.83-1.56,0-2.34l-.6-.59h0a.8.8,0,0,0-.32.09,3.69,3.69,0,0,1,.5,1.71,3.51,3.51,0,0,1-.35,1.6Z" />
          <path data-classname="cls-28" d="M28.59,18l-.6.58a1.55,1.55,0,0,1,.87.88,11.33,11.33,0,0,0,1-1.38,1.55,1.55,0,0,1-.72-.37C29.1,18,29,18.29,28.59,18Z" />
          <path data-classname="cls-28" d="M29.69,17a2.44,2.44,0,0,1,.81,0,8.77,8.77,0,0,0,.39-1c-.19-.21-.48-.39-.7-.62C30.51,16.06,29.76,16.38,29.69,17Z" />
          <path data-classname="cls-28" d="M29.17,17.7c-.05-.54.2-1.21-.58-1.47h0A3.37,3.37,0,0,0,29.17,17.7Z" />
          <path data-classname="cls-17" d="M28.29,11.81l-.13-.2A.26.26,0,0,0,28.29,11.81Z" />
          <path data-classname="cls-17" d="M25,10.34h0c.82.49,1.59,1.23,2.66.58h0c.58-.29.58-.29.59.89l-.3.65h0c.5.29,1-.14,1.48-.06h0l-.3-2.07h0c-1.11-.89-2.36-.17-3.53-.29h0Z" />
          <polygon data-classname="cls-29" points="24.45 15.05 24.45 15.05 24.45 15.05 24.44 15.06 24.45 15.05" />
          <path data-classname="cls-29" d="M24.58,18.06c1.48-.29,1.26-1.14.77-2.12h0c-.1-.4-.21-.8-.31-1.19l-.59.31a.3.3,0,0,1,0,.13c-.15.38-.09.88-.56,1.07-.07,0-.3-.16-.3-.23a1.12,1.12,0,0,1,.86-1l0,0c-.07-.45-.75-.6-.58-1.17l-.9.89h0c-.2.69-.4,1.38-.61,2.07h0c0,.85,1,1.25,1,1.94.08,1-1.55-.17-1.57,1,.69-.3,1,.29,1.46.59a1.09,1.09,0,0,1,.58-.89h0C23.89,18.89,23.83,18.21,24.58,18.06Z" />
          <path data-classname="cls-30" d="M28,18.58c-.41,0-.41-.28-.29-.58a1.1,1.1,0,0,0-.59-.89c.06.63.47,1.26-.84,1.24-1.1,0-1.34,1.08-.63,2a1.09,1.09,0,0,1,1.19-.3c.47,0,1.1-.27,1.46,0,.17-.17.35-.33.51-.5l.06-.07A1.55,1.55,0,0,0,28,18.58Z" />
          <path data-classname="cls-31" d="M26.83,20.06h0a1.09,1.09,0,0,0-1.19.3l.58,1.18h0l.7-.43A5.42,5.42,0,0,1,26.83,20.06Z" />
          <path data-classname="cls-32" d="M25.63,21.83h0c0-.51-.12-1-.7-1.12s-.82-.73-1.07-1.24h0a1.09,1.09,0,0,0-.58.89l-.29.58h0l.58.31,1.24,1,.13,0,.28-.14A.68.68,0,0,1,25.63,21.83Z" />
          <path data-classname="cls-33" d="M26.22,21.54l-.59.29h0a.68.68,0,0,0-.41.22,9.32,9.32,0,0,0,1-.5Z" />
          <path data-classname="cls-34" d="M28,15.63h0c-.73.23-.83.84-.87,1.48h0a1.1,1.1,0,0,1,.59.89C28.2,17.26,27.93,16.42,28,15.63Z" />
          <path data-classname="cls-35" d="M28.29,11.81a.26.26,0,0,1-.13-.2l-.46-.69h0c.05.62-.79,1.12-.26,1.81h0l.55-.27h0Z" />
          <path data-classname="cls-14" d="M28.59,16.23a1.53,1.53,0,0,0,0,1.77c.39.29.51,0,.59-.29h0A3.37,3.37,0,0,1,28.59,16.23Z" />
          <path data-classname="cls-36" d="M15.33.58h0A2.07,2.07,0,0,1,15.1,0h-.66C14.59.35,14.77.67,15.33.58Z" />
          <path data-classname="cls-37" d="M8.21.89A1.07,1.07,0,0,0,7.82,0H6.73a1.39,1.39,0,0,1,0,.3Z" />
          <path data-classname="cls-38" d="M6.76.3a.36.36,0,0,0,0,.59h0c.51,0,.89.69,1.48.3A2,2,0,0,1,10,2.66h0l.88-.58h0V.9l.45-.9H7.82a1.07,1.07,0,0,1,.39.89Z" />
          <path data-classname="cls-39" d="M18.87.31,18.56.9h0c.19.29.39.58.58.88h0l.61.29h0l1.46-.59h0l.3-.9L21.22.31a.64.64,0,0,1-1,.38c-.32-.32,0-.47.21-.64-.31,0-.61,0-.93,0A.94.94,0,0,1,18.87.31Z" />
          <path data-classname="cls-40" d="M20.19.69a.64.64,0,0,0,1-.38,1.1,1.1,0,0,1,.14-.18c-.31,0-.63-.07-1-.08C20.16.22,19.87.37,20.19.69Z" />
          <path data-classname="cls-41" d="M17.38,0V.6h0l1.19.3.31-.59c.06-.08,0-.19-.05-.31H17.38Z" />
          <path data-classname="cls-42" d="M20,2.68a1.41,1.41,0,0,1,.29,0c1.12-.06,1.56.63,1.62,1.6,0,.54,0,1,.74.75h0L23,4.72h0A3.94,3.94,0,0,1,22.26,3c-.13-.67-.3-1.31-1.05-1.56h0l-1.46.59h0C19.85,2.27,19.94,2.47,20,2.68Z" />
          <path data-classname="cls-43" d="M23,4.72h0L22.69,5h0c0,.4-.36.61-.6.88A5,5,0,0,1,23,8.27c.8-.75.54-1.73.59-2.65h0C23.38,5.32,23.18,5,23,4.72Z" />
          <path data-classname="cls-44" d="M22.1,8.57c-.05.07-.11.12-.16.18a3.39,3.39,0,0,1,.42.35l.32-.54Z" />
          <path data-classname="cls-45" d="M22.09,5.91c-.39.51-.78.29-1.17,0h0V6.5h0l.29.3c1.16.15.71,1.11.89,1.77h.58l.3-.29A5,5,0,0,0,22.09,5.91Z" />
          <path data-classname="cls-46" d="M20.62,15.05l-.29.29h0v.59H21.5c.57-.29.61-1.24,1.47-1.17l.9-.89c.1-.4.19-.8.29-1.2h-.29l-.77.47a3.53,3.53,0,0,1-.62.9l-.19.16C22,14.81,21.19,14.77,20.62,15.05Z" />
          <path data-classname="cls-47" d="M24.45,15h0l.59-.31h0c.25-1.66.21-1.75-.88-2.08h0c-.1.4-.19.8-.29,1.2C23.7,14.44,24.38,14.59,24.45,15Z" />
          <path data-classname="cls-48" d="M19,15h-2l.16.31C17.79,15.64,18.37,15.25,19,15Z" />
          <path data-classname="cls-48" d="M20.33,15.34l.29-.29a.25.25,0,0,0,0-.07l-.33,0a1.1,1.1,0,0,1,0,.33Z" />
          <path data-classname="cls-49" d="M22.29,14.2a3.06,3.06,0,0,1-1.65.78.25.25,0,0,1,0,.07C21.19,14.77,22,14.81,22.29,14.2Z" />
          <path data-classname="cls-26" d="M28.16,11.61l.13.2c0-1.18,0-1.18-.59-.89h0Z" />
          <path data-classname="cls-50" d="M18.85,18.58h0l.31,0h0c1-.34,1.27-1.88,2.65-1.49l.55-.29c.21-.69.41-1.38.61-2.07h0c-.86-.07-.9.88-1.47,1.17h0c-.46.86-1.14.4-1.76.3h0C20.09,17.26,19.26,17.84,18.85,18.58Z" />
          <path data-classname="cls-50" d="M19.74,16.23,20,16.1A.38.38,0,0,0,19.74,16.23Z" />
          <path data-classname="cls-51" d="M21.81,17.12c.55,1.24-.92,1.31-1.19,2.07a1.23,1.23,0,0,0,1.18.58h0c0-1.17,1.65,0,1.57-1,0-.69-1-1.09-1-1.94h0Z" />
          <path data-classname="cls-52" d="M21.82,19.77h0l-.59.58h0c.39,0,.89.06.76.63s-.7.73-1.28.54l-.08,0h0a2.64,2.64,0,0,1,0,.29h0l.59.58h0c.92-.09,1-1.18,1.77-1.47h0l.29-.58C22.83,20.06,22.51,19.47,21.82,19.77Z" />
          <path data-classname="cls-53" d="M24.45,15.05h0a1.12,1.12,0,0,0-.86,1c0,.07.23.25.3.23.47-.19.41-.69.56-1.07a.3.3,0,0,0,0-.13Z" />
          <path data-classname="cls-54" d="M26.83,20.06h0a5.42,5.42,0,0,0,.09,1.06A9.94,9.94,0,0,0,28.29,20C27.93,19.79,27.3,20.08,26.83,20.06Z" />
          <path data-classname="cls-55" d="M23.32,22.7a9.74,9.74,0,0,0,1.49-.46l-1.24-1C23.49,21.74,23.4,22.22,23.32,22.7Z" />
          <path data-classname="cls-56" d="M23.57,21.25,23,20.94h0c-.76.29-.85,1.38-1.77,1.47h0l.29.29.29,0h0a2.9,2.9,0,0,1,1,.1l.54-.13C23.4,22.22,23.49,21.74,23.57,21.25Z" />
          <path data-classname="cls-57" d="M0,0H0A8.14,8.14,0,0,0,.1,1.22,6,6,0,0,1,.64,0Z" />
          <path data-classname="cls-58" d="M15.33.58l.28,0h1.76V0H15.1a2.07,2.07,0,0,0,.23.58Z" />
          <polygon data-classname="cls-59" points="17.38 0.01 17.38 0 17.37 0 17.38 0.01 17.38 0.01" />
          <path data-classname="cls-60" d="M12.39.33c.28.58,0,1.16,0,1.75h0C13.72,1.92,13.83.94,14,0H12.74A1.84,1.84,0,0,1,12.39.33Z" />
          <path data-classname="cls-61" d="M12.37,2.08h0c0,.29,0,.58,0,.87h0a6.78,6.78,0,0,1,0,2.06A2.36,2.36,0,0,0,15,3.85h0c.46-.33,1.13-.52.51-1.35a1,1,0,0,1,.09-1.31h0l0-.59-.28,0h0c-.56.09-.74-.23-.89-.58H14C13.83.94,13.72,1.92,12.37,2.08Z" />
          <path data-classname="cls-62" d="M7.34,2.36,6.76.89h0a.36.36,0,0,1,0-.59,1.39,1.39,0,0,0,0-.3H5.34a4.35,4.35,0,0,1,.25,1.19C6.28,1.42,6.46,2.42,7.34,2.36Z" />
          <path data-classname="cls-63" d="M12.06,3.25h0c-.68-.09-1.55,0-1.17-1.17L10,2.66h0L9.42,3H8.83c0,.19,0,.38,0,.57h0l.6.32c1.21,0,1.53.65,1.34,1.7-.16.88-.34,1.75-.52,2.63h.38A32.43,32.43,0,0,1,12.06,3.25Z" />
          <path data-classname="cls-64" d="M12.37,2.08h0c0-.59.3-1.17,0-1.75-.72-.39-1,.37-1.5.57V2.08h0c-.38,1.17.49,1.08,1.17,1.17h0l.29-.3h0C12.36,2.66,12.37,2.37,12.37,2.08Z" />
          <path data-classname="cls-65" d="M8.24,1.19c-.59.39-1-.29-1.48-.3h0l.58,1.47A2.45,2.45,0,0,1,8.83,3h.59C9.26,2.21,8.26,2,8.24,1.19Z" />
          <path data-classname="cls-66" d="M8.24,1.19c0,.84,1,1,1.18,1.77l.59-.3h0A2,2,0,0,0,8.24,1.19Z" />
          <path data-classname="cls-67" d="M12.39.33A1.84,1.84,0,0,0,12.74,0h-1.4l-.45.9C11.39.7,11.67-.06,12.39.33Z" />
          <path data-classname="cls-68" d="M17.06,1.31c.65.06,1.2.19.91,1.06h0l.3,1.18h0l.87-1.77h0c-.19-.3-.39-.59-.58-.88h0L17.37.6H15.61l0,.59h0C16.08,1.5,16.58,1.27,17.06,1.31Z" />
          <path data-classname="cls-69" d="M18.27,3.55h0l-.89.89h.89c.42-.63,1.27-.39,1.76-.88,0-.29,0-.59,0-.88h0c-.1-.21-.19-.41-.29-.61h0l-.61-.29h0Z" />
          <path data-classname="cls-70" d="M18.87.31a.94.94,0,0,0,.6-.3h-.65C18.89.12,18.93.23,18.87.31Z" />
          <path data-classname="cls-71" d="M22,4.28c-.06-1-.5-1.66-1.62-1.6a8.49,8.49,0,0,1,.59,3.23h0c.39.29.78.51,1.17,0,.24-.27.56-.48.6-.88h0C22,5.26,22,4.82,22,4.28Z" />
          <path data-classname="cls-72" d="M20.92,5.91a8.49,8.49,0,0,0-.59-3.23,1.41,1.41,0,0,0-.29,0h0c0,.29,0,.59,0,.88h0a1.06,1.06,0,0,1,0,1.17V5h0c0,.29,0,.59,0,.88l.88.59V5.91Z" />
          <path data-classname="cls-73" d="M15.65,15h0Z" />
          <path data-classname="cls-74" d="M18.56,7.09h0a4.13,4.13,0,0,0-.17,1.1h1.86A2,2,0,0,0,18.56,7.09Z" />
          <path data-classname="cls-72" d="M21.21,6.8c-.17.47-.19,1-.33,1.47a2.9,2.9,0,0,1,1.06.48c0-.06.11-.11.16-.18C21.92,7.91,22.37,7,21.21,6.8Z" />
          <path data-classname="cls-75" d="M21.21,6.8l-.29-.3h0L20,5.91l-.89.88-.59.3h0a2,2,0,0,1,1.69,1.1,3.19,3.19,0,0,1,.63.08C21,7.79,21,7.27,21.21,6.8Z" />
          <path data-classname="cls-76" d="M17.09,15.34v.3C18.3,16,18.33,17,18.27,18c.51-.58.43-1.64,1.47-1.77h0A.38.38,0,0,1,20,16.1l.34-.17h0v-.59h0a1.1,1.1,0,0,0,0-.33l-.19,0H19C18.37,15.25,17.79,15.64,17.09,15.34Z" />
          <path data-classname="cls-77" d="M21.5,15.93H20.33L20,16.1l-.25.13C20.36,16.33,21,16.79,21.5,15.93Z" />
          <path data-classname="cls-78" d="M15.61,15h0c-.33.59-.89.6-1.46.58a1.46,1.46,0,0,0,1,.74,1.4,1.4,0,0,1,1,1.63h0c.21.19.62.17.59.59h.3a3.34,3.34,0,0,0,0-2.94v-.3L16.93,15H15.61Z" />
          <path data-classname="cls-79" d="M21.81,17.12c-1.38-.39-1.68,1.15-2.65,1.49h0v.57h0c.32.35.83.55.87,1.12l.6-1.11h0C20.89,18.43,22.36,18.36,21.81,17.12Z" />
          <path data-classname="cls-77" d="M18.26,18.58h0l.3,0h0l.29,0h0c.41-.74,1.24-1.32.89-2.35-1,.13-1,1.19-1.47,1.77Z" />
          <path data-classname="cls-80" d="M16.81,20.06l2.34-.88h0v-.57h0l-.31,0h0l-.29,0a2,2,0,0,1-1.76.87h0Z" />
          <path data-classname="cls-81" d="M21.21,20.35h0l.59-.58a1.23,1.23,0,0,1-1.18-.58h0L20,20.3a1.31,1.31,0,0,1-.27.06h0l-.06.31h0a2.33,2.33,0,0,1,0,.28l.87.59.08,0A1,1,0,0,1,21.21,20.35Z" />
          <path data-classname="cls-82" d="M21.8,22.73l-.29,0c-1.05.45-.82-.35-.88-.88h0a2.64,2.64,0,0,0,0-.29h0L19.74,21c.08.76,1.16,1.81-.58,2.07h0v.22A23.29,23.29,0,0,0,21.8,23v-.27Z" />
          <path data-classname="cls-83" d="M20.69,21.52c.58.19,1.12.16,1.28-.54s-.37-.58-.76-.63h0A1,1,0,0,0,20.69,21.52Z" />
          <path data-classname="cls-84" d="M20.63,21.83h0c.06.53-.17,1.33.88.88l-.29-.29h0Z" />
          <path data-classname="cls-84" d="M21.8,22.73h0V23l1-.17A2.9,2.9,0,0,0,21.8,22.73Z" />
          <path data-classname="cls-85" d="M.56,1.78h0C.6.63,1.38.23,2.34,0H.64A6,6,0,0,0,.1,1.22c0,.12,0,.23,0,.35Z" />
          <path data-classname="cls-86" d="M.57,2.65V2.08h0c0-.1,0-.2,0-.3h0L.15,1.57A8.88,8.88,0,0,0,.47,2.72l.1-.07Z" />
          <path data-classname="cls-87" d="M14.14,6.51a1.23,1.23,0,0,0,.6-.88L15,4.44h0V3.85A2.36,2.36,0,0,1,12.36,5a2.75,2.75,0,0,0-.29,2.08h0l1.1,1.1h.52C13.41,7.57,13.19,7,14.14,6.51Z" />
          <path data-classname="cls-88" d="M16.49,3.55A3.42,3.42,0,0,1,18,2.37h0c.29-.87-.26-1-.91-1.06-.48,0-1,.19-1.43-.12h0a1,1,0,0,0-.09,1.31c.62.83,0,1-.51,1.35h0v.59C15.66,4.39,16,3.82,16.49,3.55Z" />
          <path data-classname="cls-89" d="M12.07,7.09A2.75,2.75,0,0,1,12.36,5a6.78,6.78,0,0,0,0-2.06h0l-.29.3h0a32.43,32.43,0,0,0-1.42,4.94H11l.25-.51C11.13,7,11.57,7,12.07,7.09Z" />
          <path data-classname="cls-90" d="M7.34,2.36c-.88.06-1.06-.94-1.75-1.17l-.3.59h0v.29l.6.59h0a6.66,6.66,0,0,1,3,.88h0c0-.19,0-.38,0-.57h0A2.45,2.45,0,0,0,7.34,2.36Z" />
          <path data-classname="cls-91" d="M4.12.3c.65.29.79,1,1.17,1.48h0l.3-.59A4.35,4.35,0,0,0,5.34,0H4.22l-.1.3Z" />
          <path data-classname="cls-92" d="M4.12.3h0l.1-.3H2.34Z" />
          <path data-classname="cls-93" d="M9.35,6.53l0,0h0Z" />
          <path data-classname="cls-93" d="M9.44,3.86l-.31.57h0c1,1.2.73,2.48.43,3.76h.7c.18-.88.36-1.75.52-2.63C11,4.51,10.65,3.82,9.44,3.86Z" />
          <path data-classname="cls-94" d="M5.89,2.66h0c0,.1,0,.2,0,.3h0L8,3.85a1.19,1.19,0,0,1,1.16.58h0l.31-.57-.6-.32h0A6.66,6.66,0,0,0,5.89,2.66Z" />
          <path data-classname="cls-95" d="M17.38,4.44l.89-.89h0L18,2.37h0a3.42,3.42,0,0,0-1.48,1.18,6.47,6.47,0,0,0-.57,2.94h0c.29,0,.58.21.87,0h0l.3-.87A1.55,1.55,0,0,1,17.38,4.44Z" />
          <path data-classname="cls-96" d="M20,5h0v-.3a1,1,0,0,0-.94.2c-.66.61-.76,0-.82-.49h-.89a1.55,1.55,0,0,0-.29,1.18c.52.46.81,1.15,1.47,1.47h0l.59-.3C18.9,5.93,19.69,5.59,20,5Z" />
          <path data-classname="cls-97" d="M18.27,4.44h0c.06.53.16,1.1.82.49a1,1,0,0,1,.94-.2,1.06,1.06,0,0,0,0-1.17h0C19.54,4.05,18.69,3.81,18.27,4.44Z" />
          <path data-classname="cls-98" d="M1.16,2.94h0l-.3-.87H.57v.57h0A1.82,1.82,0,0,1,.57,3a7.78,7.78,0,0,0,.7,1.38A2,2,0,0,0,1.16,2.94Z" />
          <path data-classname="cls-97" d="M20,5c-.34.56-1.13.9-.88,1.76L20,5.91c0-.29,0-.59,0-.88Z" />
          <path data-classname="cls-99" d="M15.61,15.05H14.49v0Z" />
          <path data-classname="cls-100" d="M17.09,5.62l-.3.87h0a1.17,1.17,0,0,1,.29.89h0c0,.27-.16.54-.15.81h1.46a4.13,4.13,0,0,1,.17-1.1C17.9,6.77,17.61,6.08,17.09,5.62Z" />
          <path data-classname="cls-101" d="M18.26,18.58h0V18c.06-1,0-2-1.18-2.36a3.34,3.34,0,0,1,0,2.94h1.17Z" />
          <path data-classname="cls-102" d="M12.68,17.42h0c1.42-1.32,2.4,0,3.52.58a1.4,1.4,0,0,0-1-1.63,1.46,1.46,0,0,1-1-.74c0-.09,0-.18,0-.28h0c-.66.41-.75,1.49-1.77,1.46v.6Z" />
          <path data-classname="cls-103" d="M12.68,17.42h0l.29.28h0c1.24-.66,2.1-.23,2.93,1.48h0a1.21,1.21,0,0,0,.88.3h0v-.88c0-.42-.38-.4-.59-.59h0C15.08,17.43,14.1,16.1,12.68,17.42Z" />
          <path data-classname="cls-104" d="M16.8,19.47a2,2,0,0,0,1.76-.87h0l-.3,0H16.79Z" />
          <path data-classname="cls-105" d="M14.15,15.63c.57,0,1.13,0,1.46-.58H14.14v.3h0C14.14,15.45,14.15,15.54,14.15,15.63Z" />
          <path data-classname="cls-106" d="M19.15,19.18h0l-2.34.88.3.3h0a4.4,4.4,0,0,0,2.64,0h0A1.31,1.31,0,0,0,20,20.3C20,19.73,19.47,19.53,19.15,19.18Z" />
          <path data-classname="cls-107" d="M16.81,20.06l0-.58h0a1.21,1.21,0,0,1-.88-.3c-.21,1.32-1.16.44-1.76.59l.29.59h0A1.87,1.87,0,0,1,15,21.82h0l.89-.26,1.2-1.2h0Z" />
          <path data-classname="cls-108" d="M19.69,20.67l.06-.31h0a4.4,4.4,0,0,1-2.64,0h0l-1.2,1.2a1.63,1.63,0,0,1-.29,1.17l.88.58c.48.59.25,1.17,0,1.76,0,.49,0,1,0,1.48.67-.13,1.29-.15.84-1.21-.28-.66.3-1.38.63-2C17.67,21.86,18,20.81,19.69,20.67Z" />
          <path data-classname="cls-109" d="M19.69,20.67c-1.71.14-2,1.19-1.72,2.64L19.16,23h0c1.74-.26.66-1.31.58-2.07a2.33,2.33,0,0,0,0-.28Z" />
          <path data-classname="cls-110" d="M19.16,23,18,23.31c-.33.65-.91,1.37-.63,2,.45,1.06-.17,1.08-.84,1.21a3.34,3.34,0,0,1,.59,2.06v0h0v0L18,28l.73-1.65-.29-3.12h0l.74,0V23Z" />
          <path data-classname="cls-111" d="M18.56,28.32a.71.71,0,0,1,.28-.44l-.14-1.5L18,28Z" />
          <path data-classname="cls-112" d="M18.27,31.85h0v.3c.11.4.21.8.31,1.19a4.37,4.37,0,0,0,.82.27l-.14-1.45A.73.73,0,0,0,18.27,31.85Z" />
          <path data-classname="cls-113" d="M18.86,30.38l-.59.59v.88a.73.73,0,0,1,1,.31l-.06-.64-.1-1.06a.79.79,0,0,1-.23-.08Z" />
          <path data-classname="cls-114" d="M18.56,28.32a6,6,0,0,0,.3,2.06.79.79,0,0,0,.23.08l-.25-2.58A.71.71,0,0,0,18.56,28.32Z" />
          <path data-classname="cls-115" d="M1.76,1.19h0c.63-.73,1.31-1,1.8.07.39.81,1.15.57,1.73.81V1.78h0C4.91,1.28,4.77.59,4.12.3h0L2.34,0C1.38.23.6.63.56,1.78h0c0,.1,0,.2,0,.3H.86A.79.79,0,0,1,1.76,1.19Z" />
          <path data-classname="cls-116" d="M.57,2.65l-.1.07A2,2,0,0,0,.57,3a1.82,1.82,0,0,0,0-.33Z" />
          <path data-classname="cls-117" d="M14.44,8.19H15a1.18,1.18,0,0,0-.24-.49Z" />
          <path data-classname="cls-118" d="M12.07,7.09c-.5-.11-.94-.12-.87.59a1.38,1.38,0,0,1,.7.51h1.27l-1.1-1.1Z" />
          <path data-classname="cls-119" d="M16.49,3.55c-.51.27-.83.84-1.46.89h0l-.29,1.19.57.29h0c.63-.25.24.56.61.57h0A6.47,6.47,0,0,1,16.49,3.55Z" />
          <path data-classname="cls-120" d="M15.31,5.92h0l-.57-.29a1.23,1.23,0,0,1-.6.88c.09.56-.52,1.22.2,1.68h.1l.3-.49Z" />
          <path data-classname="cls-121" d="M14.14,6.51c-1,.45-.73,1.06-.45,1.68h.65C13.62,7.73,14.23,7.07,14.14,6.51Z" />
          <path data-classname="cls-87" d="M11.2,7.68,11,8.19h1A1.38,1.38,0,0,0,11.2,7.68Z" />
          <path data-classname="cls-122" d="M3.56,1.26c-.49-1-1.17-.8-1.8-.07.55-.15.79.12,1.16.55.74.85,1.64,1.62,3,1.22h0c0-.1,0-.2,0-.3h0l-.6-.59C4.71,1.83,4,2.07,3.56,1.26Z" />
          <path data-classname="cls-123" d="M9.35,6.53v0h-.8L7.94,7.68c0,.17,0,.33,0,.49l.25,0H9A1.47,1.47,0,0,1,9.35,6.53Z" />
          <path data-classname="cls-124" d="M9.13,4.43h0A1.19,1.19,0,0,0,8,3.85c.18,1,1.31,1.56,1.41,2.64h0l0,0A1.47,1.47,0,0,0,9,8.19h.59C9.86,6.91,10.15,5.63,9.13,4.43Z" />
          <path data-classname="cls-125" d="M5.87,3h0c-1.31.4-2.21-.37-2.95-1.22-.37-.43-.61-.7-1.16-.55h0V2.66A3.48,3.48,0,0,1,5,4.15H5c.1.1.19.21.29.32,2.62-.1,3,.15,3.27,2h.83C9.28,5.41,8.15,4.88,8,3.85Z" />
          <path data-classname="cls-121" d="M17.08,7.38a1.17,1.17,0,0,0-.29-.89h0c-.29.21-.58,0-.87,0h0c-.37,0,0-.82-.61-.57h0L14.74,7.7a1.18,1.18,0,0,1,.24.49H16.3C16.38,7.8,16.36,7.22,17.08,7.38Z" />
          <path data-classname="cls-126" d="M3.23,4.44h0L5,4.15A3.48,3.48,0,0,0,1.76,2.66l-.6.28a2,2,0,0,1,.11,1.42,8,8,0,0,0,.82,1.09A3.32,3.32,0,0,1,3.23,4.44Z" />
          <path data-classname="cls-127" d="M1.76,1.19a.79.79,0,0,0-.9.88h0l.3.87h0l.6-.28Z" />
          <path data-classname="cls-128" d="M14.14,15.05h.29l0,0h-.32v0Z" />
          <path data-classname="cls-129" d="M17.08,7.38h0c-.72-.16-.7.42-.78.81h.63C16.92,7.92,17.08,7.65,17.08,7.38Z" />
          <path data-classname="cls-130" d="M14.15,21.52h0l.29,0h0c0-.4,0-.79,0-1.18h0l-.29-.59A2.63,2.63,0,0,1,13,17.7h0l-.29-.28h-.31l-.28.32h0c.11,1.17,1,1.91,1.59,2.79A1.82,1.82,0,0,1,14.15,21.52Z" />
          <path data-classname="cls-131" d="M11.5,17.63h0l.59.1h0l.28-.32v-.6c0-.09,0-.19,0-.29l-1-1.49h-.69C10.92,15.91,11.71,16.62,11.5,17.63Z" />
          <path data-classname="cls-132" d="M12.36,16.52c0,.1,0,.2,0,.29,1,0,1.11-1,1.77-1.46h0v-.3h0v0H12.71l-.05,0Z" />
          <path data-classname="cls-133" d="M15.9,19.18h0C15.07,17.47,14.21,17,13,17.7h0a2.63,2.63,0,0,0,1.17,2.07C14.74,19.62,15.69,20.5,15.9,19.18Z" />
          <path data-classname="cls-134" d="M15,21.82h0v0c0,.43.12.78.6.89h0a1.63,1.63,0,0,0,.29-1.17l-.89.26Z" />
          <path data-classname="cls-135" d="M14.71,21.84H15v0h0a1.87,1.87,0,0,0-.59-1.46h0c0,.39,0,.78,0,1.18h0Z" />
          <path data-classname="cls-136" d="M15,21.84h-.31a.89.89,0,0,0,.09,1.08A2,2,0,0,1,15,25.36h0l.29.31.31,0h0a9.87,9.87,0,0,1,.88-2.34l-.88-.58h0C15.14,22.62,15,22.27,15,21.84Z" />
          <path data-classname="cls-136" d="M15.9,25.67h0c-.05.23-.22.6-.13.69.89.85.56,1.85.43,2.84h0l.88-.56v0a3.34,3.34,0,0,0-.59-2.06c0-.49,0-1,0-1.48Z" />
          <path data-classname="cls-137" d="M15.62,25.65h0l.28,0,.59-.6c.26-.59.49-1.17,0-1.76A9.87,9.87,0,0,0,15.62,25.65Z" />
          <path data-classname="cls-138" d="M17.09,30.39h1.77a6,6,0,0,1-.3-2.06L18,28l-.88.58v0h0Z" />
          <path data-classname="cls-139" d="M18.56,34.51c.06.56.59.65,1,.87l-.16-1.77a4.37,4.37,0,0,1-.82-.27Z" />
          <path data-classname="cls-140" d="M16.48,33h0l0,0Z" />
          <path data-classname="cls-140" d="M18.56,34.51V33.34c-.1-.39-.2-.79-.31-1.19-.72,0-1.41.11-1.75.89l-.31.88h0l0,0,.74.42-.06-.13a.49.49,0,0,1,.15-.75.62.62,0,0,1,.53,0c.32.19.22.49.09.77s-.13.27-.19.41l.69.4C18.28,34.9,18.41,34.7,18.56,34.51Z" />
          <path data-classname="cls-141" d="M17.08,30.67h0l-.59.31h0c0,.68,0,1.36,0,2l0,0c.34-.78,1-.86,1.75-.89v-.3h0V31Z" />
          <path data-classname="cls-141" d="M16.34,31.28l.15-.3A.31.31,0,0,0,16.34,31.28Z" />
          <path data-classname="cls-114" d="M17.09,30.39c0,.09,0,.19,0,.28l1.19.3.59-.59Z" />
          <path data-classname="cls-142" d="M12.71,15h-.09l0,0Z" />
          <path data-classname="cls-143" d="M7.64,15.06h.3s0,0,0,0H7.62l0,0Z" />
          <path data-classname="cls-143" d="M5,15.05c.35.14.81.41,1,0Z" />
          <path data-classname="cls-143" d="M7.77,8.17h.17c0-.16,0-.32,0-.49C7.88,7.84,7.83,8,7.77,8.17Z" />
          <path data-classname="cls-144" d="M8.55,6.5h0c-.25-1.88-.65-2.13-3.27-2L5,5c.48,0,1,0,1.44,0s1.07-.14,1.34.56-.57.75-.72,1.2A1.56,1.56,0,0,1,7.35,8a.85.85,0,0,1-.06.17l.48,0c.06-.17.11-.33.17-.49Z" />
          <path data-classname="cls-145" d="M9.72,15.05c.33.76.13,1.72.87,2.33h0l.57.29h0l.34,0h0c.21-1-.58-1.72-.82-2.6h-1v0Z" />
          <path data-classname="cls-146" d="M12.66,15.05l0,0H11.37l1,1.49Z" />
          <path data-classname="cls-147" d="M5,5H5l.28-.55c-.1-.11-.19-.22-.29-.32H5l-1.76.29h0c.08.63-.43,1-.81,1.37A7.37,7.37,0,0,0,3,6.33C3.58,5.78,4.06,5.07,5,5Z" />
          <path data-classname="cls-148" d="M7.35,8a1.56,1.56,0,0,0-.29-1.18c-.65.74-1.19.84-1.47-.29h0a6,6,0,0,0-.7.51.59.59,0,0,0-.17.4,8.15,8.15,0,0,0,2.42.7A1,1,0,0,0,7.35,8Z" />
          <path data-classname="cls-149" d="M3.23,4.44h0a3.32,3.32,0,0,0-1.14,1c.11.12.21.24.33.36C2.8,5.41,3.31,5.07,3.23,4.44Z" />
          <path data-classname="cls-150" d="M11.15,18.3h0l.09.29c1.1.85,1.45,2.43,2.88,2.95l0,0h0a1.82,1.82,0,0,0-.47-1c-.6-.88-1.48-1.62-1.59-2.79h0l-.59-.1h0l-.34,0h0C11.16,17.88,11.15,18.09,11.15,18.3Z" />
          <path data-classname="cls-151" d="M14.14,24.5h0l.88.86h0a2,2,0,0,0-.22-2.44.89.89,0,0,1-.09-1.08l-.27-.3h0l-.29,0h0l0,0c-.39.37-.58.75-.1,1.22a.74.74,0,0,1,.11,1.14c0,.2,0,.4,0,.6Z" />
          <path data-classname="cls-152" d="M15.31,25.67,15,25.36h0l-.88-.86h0c-.39.68-.78,1.36-1.16,2.05h0c.94,0,.73,1.21,1.45,1.47h0C14.73,27.24,15,26.45,15.31,25.67Z" />
          <path data-classname="cls-153" d="M15.62,25.65h0l-.31,0c-.29.78-.58,1.57-.88,2.35h0c.53.26,1.23.33,1.18,1.17h.59c.13-1,.46-2-.43-2.84-.09-.09.08-.46.13-.69h0Z" />
          <path data-classname="cls-154" d="M16.2,29.2h0c-.1.39-.2.78-.31,1.17h0c0,.58,0,1.16,0,1.74h0l.42-.84a.31.31,0,0,1,.15-.3h0l.59-.31h0c0-.09,0-.19,0-.28V28.63h0Z" />
          <path data-classname="cls-155" d="M18.56,34.51c-.15.19-.28.39-.43.58l1.48.84h0l-.06-.55C19.15,35.16,18.62,35.07,18.56,34.51Z" />
          <path data-classname="cls-156" d="M16.2,33.92l.31-.88h0l-2.05-.29h0a.8.8,0,0,0-.06.2l1.73,1Z" />
          <path data-classname="cls-157" d="M16.2,33.92h-.1l.08,0,0,0Z" />
          <path data-classname="cls-158" d="M17.54,33.51a.62.62,0,0,0-.53,0,.49.49,0,0,0-.15.75l.06.13.52.3c.06-.14.13-.27.19-.41S17.86,33.7,17.54,33.51Z" />
          <path data-classname="cls-159" d="M16.49,31h0l-.15.3-.42.84v0h0L15,31.86l-.59.88,2.05.29h0C16.48,32.34,16.49,31.66,16.49,31Z" />
          <path data-classname="cls-160" d="M1.46,16.24h0c.31.8-.36,2.32,1.46,2h0A2.5,2.5,0,0,1,5,15.34H5a2.71,2.71,0,0,0,0-.29H5l-3.83,0C1,15.39,1.18,15.79,1.46,16.24Z" />
          <path data-classname="cls-161" d="M5,15.05H5a2.71,2.71,0,0,1,0,.29H5c-.19,1.18-.39,2.36-.58,3.54h0l.58.59c.44-.36.82-.8,1.37,0a4.9,4.9,0,0,0,1.28.93h0c0-.2,0-.4,0-.59-1.89-1.11-1.9-1.22-.26-3.55.08-.39.16-.78.25-1.16h0l0,0H6C5.81,15.46,5.35,15.19,5,15.05Z" />
          <path data-classname="cls-162" d="M8.83,15.94h0c.52-.07.58-.6.89-.89v0H8s0,0,0,0A1,1,0,0,0,8.83,15.94Z" />
          <path data-classname="cls-163" d="M8.26,17.42l.58,0h0l.58-.29c-.2-.39-.4-.78-.59-1.17h0a1,1,0,0,1-.89-.88h-.3c-.09.38-.17.77-.25,1.16A2.69,2.69,0,0,1,8.26,17.42Z" />
          <path data-classname="cls-164" d="M5.59,6.5h0c.28,1.13.82,1,1.47.29.15-.45,1-.44.72-1.2S6.94,5.06,6.44,5,5.48,5,5,5H5Z" />
          <path data-classname="cls-164" d="M7.14,8.11l.15,0A.85.85,0,0,0,7.35,8,1,1,0,0,1,7.14,8.11Z" />
          <path data-classname="cls-165" d="M9.42,17.11a1.4,1.4,0,0,0,1.17.27h0c-.74-.61-.54-1.57-.87-2.33h0c-.31.29-.37.82-.89.89h0C9,16.33,9.22,16.72,9.42,17.11Z" />
          <path data-classname="cls-166" d="M9.42,17.11l-.58.29h0l.58.6h0a3.31,3.31,0,0,0,1.73.3c0-.21,0-.42,0-.63h0l-.57-.29h0A1.4,1.4,0,0,1,9.42,17.11Z" />
          <path data-classname="cls-167" d="M5.59,6.5h0L5,5c-.93,0-1.41.75-2,1.3A8,8,0,0,0,4.72,7.41.59.59,0,0,1,4.89,7,6,6,0,0,1,5.59,6.5Z" />
          <path data-classname="cls-168" d="M1.46,16.24c-.28-.45-.44-.85-.29-1.17H0v2.38C.78,17.51.94,16.6,1.46,16.24Z" />
          <path data-classname="cls-169" d="M1.17,18.87l2.05.32a1.13,1.13,0,0,0-.3-.91h0c-1.82.28-1.15-1.24-1.46-2h0C.94,16.6.78,17.51,0,17.46v.6a1.78,1.78,0,0,1,.87.54Z" />
          <path data-classname="cls-170" d="M12.83,23.22c.32.09.36.4.43.68h.87A.74.74,0,0,0,14,22.76c-.48-.47-.29-.85.1-1.22-1.43-.52-1.78-2.1-2.88-2.95,0,.79-.05,1.58-.08,2.37h0a5.41,5.41,0,0,0,1.2,2.64C12.44,23.37,12.53,23.13,12.83,23.22Z" />
          <path data-classname="cls-171" d="M11.24,18.59l-.09-.29h0A3.31,3.31,0,0,1,9.42,18h0c-.19.4-.42.89,0,1.21,1.06.88.93,2,.91,3.2h0l.32-.54c0-.42.05-.79.54-.91C11.19,20.17,11.21,19.38,11.24,18.59Z" />
          <path data-classname="cls-172" d="M14.14,24.5h0c0-.2,0-.4,0-.6h-.87a3.52,3.52,0,0,0-.6,2.65h0l.1.24.22-.24h0C13.36,25.86,13.75,25.18,14.14,24.5Z" />
          <path data-classname="cls-173" d="M14.43,28h0c-.72-.26-.51-1.43-1.45-1.47h0l-.22.24-.1-.24h-.29c-.18.49-.36,1-.55,1.48.54.18,1.53-.11,1,1.1-.12.3,0,.35.69.08Z" />
          <path data-classname="cls-174" d="M15.61,29.2c.05-.84-.65-.91-1.18-1.17h0l-.88,1.17v.89l.29.29C14.7,30.4,14.67,29.07,15.61,29.2Z" />
          <path data-classname="cls-175" d="M15.61,29.2h0c-.94-.13-.91,1.2-1.77,1.18a2.31,2.31,0,0,0,2.05,0h0c.11-.39.21-.78.31-1.17h-.59Z" />
          <path data-classname="cls-176" d="M15.91,32.15h0v0c0-.58,0-1.16,0-1.74h0a2.31,2.31,0,0,1-2.05,0l-.29-.29c0,.1,0,.2,0,.3h0a1.18,1.18,0,0,1-.28.88l0,.59c.57-.69,1.15.07,1.72,0h0Z" />
          <path data-classname="cls-177" d="M14.43,32.74l.59-.88h0c-.57.07-1.15-.69-1.72,0l-.14.38,1.21.7a.8.8,0,0,1,.06-.2Z" />
          <path data-classname="cls-178" d="M4.41,18.88h0c.19-1.18.39-2.36.58-3.54H5a2.5,2.5,0,0,0-2.07,2.94h0a1.13,1.13,0,0,1,.3.91h0C3.74,19.57,4,18.85,4.41,18.88Z" />
          <path data-classname="cls-179" d="M3.83,22.42h0l.28.3h.3a5.1,5.1,0,0,1,.76-2.31c.43-.73,1.08-.48,1.49-.17.6.46-.14.67-.36.95-.42.52-.87,1-1.3,1.53l.59.3h.88l1.77-.29h0l.29-.59c-.29-.59-.59-1.18-.89-1.77h0a4.9,4.9,0,0,1-1.28-.93c-.55-.76-.93-.32-1.37,0C5,20.59,3.46,21.14,3.83,22.42Z" />
          <path data-classname="cls-180" d="M8.53,19.76h0a5.17,5.17,0,0,0-.27-2.34h0a2.69,2.69,0,0,0-.87-1.2c-1.64,2.33-1.63,2.44.26,3.55Z" />
          <path data-classname="cls-181" d="M2.75,19.69c.56.35.45,1.06.49,1.61a4.81,4.81,0,0,1-.3,1.41h.29l.6-.3h0C3.46,21.14,5,20.59,5,19.47l-.58-.59h0c-.43,0-.67.69-1.19.31h0l-2.05-.32c-.29.57.08,1,.29,1.49h0C2,20.24,2.06,19.27,2.75,19.69Z" />
          <path data-classname="cls-182" d="M8.53,22.13h0c.29.19.58.38.86.58h0c-.32-1,0-2.15-.87-2.94H7.65c0,.19,0,.39,0,.59h0C7.94,21,8.24,21.54,8.53,22.13Z" />
          <path data-classname="cls-183" d="M9.39,19.21c-.39-.32-.16-.81,0-1.21h0l-.58-.6h0l-.58,0h0a5.17,5.17,0,0,1,.27,2.34h0c.92.79.55,2,.87,2.94h0l.57,0h0l.3-.32h0C10.32,21.25,10.45,20.09,9.39,19.21Z" />
          <path data-classname="cls-184" d="M1.16,21c-.29-.4,0-.51.3-.59-.21-.47-.58-.92-.29-1.49l-.3-.27a2.08,2.08,0,0,1-.87.49v2.07a2.46,2.46,0,0,1,.3.4C.72,21.53,1.07,21.42,1.16,21Z" />
          <path data-classname="cls-185" d="M.87,18.6A1.78,1.78,0,0,0,0,18.06v1A2.08,2.08,0,0,0,.87,18.6Z" />
          <path data-classname="cls-186" d="M.3,21.56h0a2.46,2.46,0,0,0-.3-.4V23l.58-.86Z" />
          <path data-classname="cls-187" d="M.85,35.1l0-.82h0l-.31-.09a5.78,5.78,0,0,1-.57.12v.82a8,8,0,0,1,.85,0Z" />
          <path data-classname="cls-188" d="M1.16,28.91,0,28.44v1.3A1.61,1.61,0,0,1,1.16,28.91Z" />
          <path data-classname="cls-188" d="M1.17,29.49h0c-.33.26-.58.68-1.17.68V33l1.47-2.91Z" />
          <path data-classname="cls-189" d="M0,25.6v1.17a.94.94,0,0,0,.28-.22Z" />
          <path data-classname="cls-190" d="M12.36,24.48h0V23.6A5.41,5.41,0,0,1,11.16,21h0c-.49.12-.54.49-.54.91.54.66,0,1.31,0,2a1.24,1.24,0,0,1,.63.86,1.15,1.15,0,0,0,0,.14C11.56,24.57,11.84,24.23,12.36,24.48Z" />
          <path data-classname="cls-191" d="M12.66,26.55h0a3.52,3.52,0,0,1,.6-2.65c-.07-.28-.11-.59-.43-.68s-.39.15-.47.38v.88h0A2.24,2.24,0,0,1,11.77,26a.69.69,0,0,1,.6.58h.29Z" />
          <path data-classname="cls-192" d="M10.62,21.87l-.32.54h0l-.3.32a5.3,5.3,0,0,1,0,.89,2,2,0,0,1,.63.22C10.57,23.18,11.16,22.53,10.62,21.87Z" />
          <path data-classname="cls-193" d="M13.54,30.39c0-.1,0-.2,0-.3V29.2c-.69.27-.81.22-.69-.08.49-1.21-.5-.92-1-1.1l-.06.59h0a2.93,2.93,0,0,1-.1.53c.06.67.12,1.27.16,1.71a1.48,1.48,0,0,0,.31-.13A2.51,2.51,0,0,1,13.54,30.39Z" />
          <path data-classname="cls-194" d="M11.82,28c.19-.49.37-1,.55-1.48h0a.69.69,0,0,0-.6-.58l-.39.2c.07.67.14,1.47.22,2.25a1.16,1.16,0,0,1,.16.2Z" />
          <path data-classname="cls-195" d="M13.3,31.86l0-.59c-.46,0-.93.06-1.39.13v.11l1.28.73Z" />
          <path data-classname="cls-174" d="M13.26,31.27a1.18,1.18,0,0,0,.28-.88h0a2.51,2.51,0,0,0-1.41.33,1.48,1.48,0,0,1-.31.13l0,.55C12.33,31.33,12.8,31.31,13.26,31.27Z" />
          <path data-classname="cls-196" d="M5,22.71c.43-.51.88-1,1.3-1.53.22-.28,1-.49.36-.95-.41-.31-1.06-.56-1.49.17a5.1,5.1,0,0,0-.76,2.31h0C4.61,22.8,4.8,22.89,5,22.71Z" />
          <path data-classname="cls-197" d="M4.11,22.72h0l-.28-.3h0l-.6.3h0c.19.82-.61,1.52-.3,2.35h0a1,1,0,0,0,1.18-.29.7.7,0,0,1,.59-.59h0C4.47,23.71,3.64,23.48,4.11,22.72Z" />
          <path data-classname="cls-182" d="M8.24,22.72,6.47,23h0l-.59.59h0l2.35.29h0V23.6h0Z" />
          <path data-classname="cls-182" d="M5.59,23,5,22.71c-.2.18-.39.09-.59,0h-.3c-.47.76.36,1,.59,1.47h0L5,23.9H5C5,23.5,5.35,23.28,5.59,23Z" />
          <path data-classname="cls-198" d="M9.41,22.71h0c-.28-.2-.57-.39-.86-.58h0l-.29.59h0v.88h0l1.17-.3h0c0-.19,0-.39,0-.59h0Z" />
          <path data-classname="cls-197" d="M5.59,23h0c-.24.27-.55.49-.59.89l.88-.3h0L6.47,23H5.59Z" />
          <path data-classname="cls-199" d="M2.94,22.71h0a4.81,4.81,0,0,0,.3-1.41c0-.55.07-1.26-.49-1.61s-.8.55-1.29.67h0c-.33.08-.59.19-.3.59.49-.34.72,0,.63.37s.24.73.26,1.11h0l.3,1.75h0A1.92,1.92,0,0,0,2.94,22.71Z" />
          <path data-classname="cls-200" d="M1.77,27.14h0v0l0,0h.57a4.5,4.5,0,0,0,.59-2.06h0c-.31-.83.49-1.53.3-2.35H2.94a1.92,1.92,0,0,1-.59,1.47h0c-.11.2-.22.39-.32.59H2A6.55,6.55,0,0,1,1.77,27.14Z" />
          <path data-classname="cls-201" d="M10,22.73h0l-.57,0h0c0,.2,0,.4,0,.59h0v.31a2.1,2.1,0,0,1,.56,0A5.3,5.3,0,0,0,10,22.73Z" />
          <path data-classname="cls-202" d="M1.79,21.32c.09-.35-.14-.71-.63-.37-.09.47-.44.58-.86.61h0l.28.56,1.47.31C2,22.05,1.68,21.78,1.79,21.32Z" />
          <path data-classname="cls-203" d="M1.37,23c.65.39.31,1,.09,1.51h0l.57.29c.1-.2.21-.39.32-.59h0l-.3-1.75h0L.58,22.12,0,23v.59C.38,23.17.73,22.58,1.37,23Z" />
          <path data-classname="cls-204" d="M0,35.93H0l.1-.06L0,35.8Z" />
          <path data-classname="cls-205" d="M2.05,33.91h0a.93.93,0,0,1-.88.26h0l-.28.11h0l0,.82h0l0,0h.65l1.79-1A1,1,0,0,0,2.05,33.91Z" />
          <path data-classname="cls-206" d="M1.17,32.72c0-.93.73-1.68.58-2.63H1.47L0,33v1.32a5.78,5.78,0,0,0,.57-.12C1,33.77.52,33,1.17,32.72Z" />
          <path data-classname="cls-207" d="M1.17,32.72h0C.52,33,1,33.77.57,34.19l.31.09h0l.28-.11h0l.28-.53c.51-.47,1.23-.28,1.78-.6h0v-.3A2.08,2.08,0,0,0,1.17,32.72Z" />
          <path data-classname="cls-208" d="M.87,35.12l0,0h0a8,8,0,0,0-.85,0v.67l.1.07.4-.21Z" />
          <path data-classname="cls-209" d="M1.17,29.49h0l.3.59h.28c0-.79,0-1.58,0-2.36l0-.3-.87,0h0A.83.83,0,0,1,0,27.7v.74l1.16.47C1.17,29.11,1.17,29.3,1.17,29.49Z" />
          <path data-classname="cls-206" d="M1.17,29.49c0-.19,0-.38,0-.58A1.61,1.61,0,0,0,0,29.74v.43c.59,0,.84-.42,1.17-.68Z" />
          <path data-classname="cls-210" d="M1.16,24.78l.3-.3h0C1.68,24,2,23.36,1.37,23s-1,.2-1.37.6v.85A1.58,1.58,0,0,1,1.16,24.78Z" />
          <path data-classname="cls-211" d="M.58,26.57h0c.25-.77.57-.07.86,0-.1-.58-.19-1.16-.28-1.75A1.58,1.58,0,0,0,0,24.42V25.6l.28.95Z" />
          <path data-classname="cls-212" d="M.86,27.45h0a1.23,1.23,0,0,1-.28-.88l-.3,0a.94.94,0,0,1-.28.22v.93A.83.83,0,0,0,.86,27.45Z" />
          <path data-classname="cls-213" d="M11.77,26a2.24,2.24,0,0,0,.59-1.48h0c-.52-.25-.8.09-1.11.36,0,.25.07.73.13,1.32Z" />
          <path data-classname="cls-214" d="M8,27.14l.24.25V26.9L8,27.14Z" />
          <path data-classname="cls-215" d="M11.76,28.61a1.16,1.16,0,0,0-.16-.2l.06.73a2.93,2.93,0,0,0,.1-.53Z" />
          <path data-classname="cls-216" d="M7.65,30.09l-.3.88h0l0,1L8,31.62a.38.38,0,0,1,.2-.17v-1.9l-.54.54Z" />
          <path data-classname="cls-217" d="M2.93,25.07h0a4.5,4.5,0,0,1-.59,2.06c1.21-.17,1.55,1,2.37,1.48l.58-.29a3.38,3.38,0,0,0,.3-1.77h0c-1,0-.78-1-.79-1.44,0-.84-.39-.38-.69-.33h0A1,1,0,0,1,2.93,25.07Z" />
          <path data-classname="cls-218" d="M5.88,23.6h0L5,23.9H5l-.3.29h0a.7.7,0,0,0-.59.59h0c.3-.05.66-.51.69.33,0,.44-.26,1.41.79,1.44.06-.54.13-1.08.88-.59h.59c-.47-1.7-.47-1.7,1.18-1.77,0-.1,0-.2,0-.3h0Z" />
          <path data-classname="cls-219" d="M9.41,23.3h0l-1.17.3h0v.29h0c0,.1,0,.2,0,.3a.5.5,0,0,0,.08.12,1.32,1.32,0,0,1,1.09-.7Z" />
          <path data-classname="cls-220" d="M2.94,29.51c.66-.16,1.45-.07,1.77-.9h0c-.82-.45-1.16-1.65-2.37-1.48H1.77l0,0a2.26,2.26,0,0,1,0,.26h0l0,.3A2.07,2.07,0,0,1,2.94,29.51Z" />
          <path data-classname="cls-221" d="M1.74,27.14h0A6.55,6.55,0,0,0,2,24.77H2l-.57-.29h0l-.3.3c.09.59.18,1.17.28,1.75Z" />
          <path data-classname="cls-222" d="M1.16,35.12H.87l-.37.54.67-.36c0-.06,0-.12,0-.18Z" />
          <path data-classname="cls-223" d="M3.23,33c-.55.32-1.27.13-1.78.6l.6.27h0a1,1,0,0,1,1.26.23l.79-.42A1.52,1.52,0,0,0,3.23,33Z" />
          <path data-classname="cls-224" d="M1.16,35.12c0,.06,0,.12,0,.18l.35-.19Z" />
          <path data-classname="cls-206" d="M1.17,34.17h0a.93.93,0,0,0,.88-.26h0l-.6-.27Z" />
          <path data-classname="cls-225" d="M2.94,29.51h0a2.07,2.07,0,0,0-1.17-1.78c0,.78,0,1.57,0,2.36h0c.15.95-.6,1.7-.58,2.63h0a2.08,2.08,0,0,1,2.05,0v-.3h0v-.28h0C2.73,31.32,3.29,30.36,2.94,29.51Z" />
          <path data-classname="cls-226" d="M3.23,32.44V33h0a1.52,1.52,0,0,1,.87.68l1.2-.65A4.22,4.22,0,0,0,3.23,32.44Z" />
          <path data-classname="cls-189" d="M1.74,27.17v0h0l-.3-.61c-.29,0-.61-.73-.86,0h0a1.23,1.23,0,0,0,.28.88h0l.87,0h0A2.26,2.26,0,0,0,1.74,27.17Z" />
          <path data-classname="cls-227" d="M8.24,24.19c-1.65.07-1.65.07-1.18,1.77h0c-.1.59-.19,1.18-.29,1.77.64.29.75-.47,1.18-.59H8l.24-.24v-2a1.24,1.24,0,0,1,.13-.6A.5.5,0,0,1,8.24,24.19Z" />
          <path data-classname="cls-228" d="M8,27.14c-.43.12-.54.88-1.18.59a2.71,2.71,0,0,1,0,.29c0,.7.36,1.37.09,2.09a.63.63,0,0,0,.5.86l.3-.88h0c-.12-.69-.61-1.49.54-1.76v-.94L8,27.14Z" />
          <path data-classname="cls-229" d="M7.35,31a.63.63,0,0,1-.5-.86c.27-.72-.06-1.39-.09-2.09l-.58.6h0a6.3,6.3,0,0,0,.88,3.5L7.38,32l0-1Z" />
          <path data-classname="cls-230" d="M7.65,30.09h0l.54-.54V28.33C7,28.6,7.53,29.4,7.65,30.09Z" />
          <path data-classname="cls-231" d="M8,31.62l.2-.11h0v-.06A.38.38,0,0,0,8,31.62Z" />
          <path data-classname="cls-232" d="M5.29,28.32l-.58.29h0c-.32.83-1.11.74-1.77.9h0c.35.85-.21,1.81.28,2.65h0l0,0a8.59,8.59,0,0,1,2.64-3.52Z" />
          <path data-classname="cls-233" d="M5.59,26.55a3.38,3.38,0,0,1-.3,1.77l.59.3h.3l.58-.6a2.71,2.71,0,0,0,0-.29c.1-.59.19-1.18.29-1.77H6.47Z" />
          <path data-classname="cls-217" d="M5.59,26.55,6.47,26c-.75-.49-.82.05-.88.59Z" />
          <path data-classname="cls-234" d="M3.24,32.14h0l0,0h0v.28h0a4.22,4.22,0,0,1,2.07.63l1.39-.75a10.65,10.65,0,0,0-1.16-1.53C5.31,32.29,4.16,32,3.24,32.14Z" />
          <path data-classname="cls-235" d="M6.18,28.62h-.3a8.59,8.59,0,0,0-2.64,3.52h0c.92-.14,2.07.15,2.29-1.35a10.65,10.65,0,0,1,1.16,1.53l.37-.2A6.3,6.3,0,0,1,6.18,28.62Z" />
          <path data-classname="cls-22" d="M16.49,10.92h.3a1.09,1.09,0,0,0,.35-.29,2.7,2.7,0,0,0-.35-.59l-.3.88Z" />
          <path data-classname="cls-27" d="M16.79,10.92c.17.36.35.71.52,1.07a2.09,2.09,0,0,0,0-.34,2.66,2.66,0,0,0-.21-1A1.09,1.09,0,0,1,16.79,10.92Z" />
          <path data-classname="cls-44" d="M14.13,9.45c-.1.29-.19.59-.29.88h0l.3.3.87-.29h0a3.32,3.32,0,0,1,1.78-.3,2.6,2.6,0,0,0-1.51-1A7.7,7.7,0,0,1,14.13,9.45Z" />
          <path data-classname="cls-236" d="M15,10.34c.16,1,.93.52,1.48.58l.3-.88h0A3.32,3.32,0,0,0,15,10.34Z" />
          <path data-classname="cls-236" d="M16.79,10.92h-.3c-.1.4-.2.79-.29,1.18h1.1a.45.45,0,0,0,0-.11C17.14,11.63,17,11.28,16.79,10.92Z" />
          <path data-classname="cls-48" d="M16.2,12.1c-.47.45-1.26.64-1.17,1.52,0,.46-.32.53-.56.37a1.11,1.11,0,0,0-1.2-.14,2.63,2.63,0,0,0,4-1.75Z" />
          <path data-classname="cls-237" d="M14.47,14c.24.16.6.09.56-.37-.09-.88.7-1.07,1.17-1.52.09-.39.19-.78.29-1.18h0c-.55-.06-1.32.46-1.48-.58h0l-.87.29c-.12.27-.42.62-.34.79.64,1.28-.46,1.43-1.15,1.85h0a2.85,2.85,0,0,0,.62.58A1.11,1.11,0,0,1,14.47,14Z" />
          <path data-classname="cls-73" d="M12.65,13.27c.69-.42,1.79-.57,1.15-1.85-.08-.17.22-.52.34-.79l-.3-.3h0A2.36,2.36,0,0,0,13,11.52h0a1.23,1.23,0,0,1-.69,1.09,2.73,2.73,0,0,0,.39.66Z" />
          <path data-classname="cls-74" d="M13.54,9.45h.59a7.7,7.7,0,0,0,1.15-.37A2.62,2.62,0,0,0,14.71,9a2.7,2.7,0,0,0-1.2.3l0,.14Z" />
          <path data-classname="cls-238" d="M13,11.52h0a2.36,2.36,0,0,1,.89-1.19h0c.1-.29.19-.59.29-.88h-.59l-.41.1a2.61,2.61,0,0,0-1,1.88A1.63,1.63,0,0,0,13,11.52Z" />
          <polygon data-classname="cls-48" points="12.65 13.27 12.65 13.27 12.65 13.27 12.65 13.27 12.65 13.27" />
          <path data-classname="cls-239" d="M13,11.52h0a1.63,1.63,0,0,1-.85-.09,1.64,1.64,0,0,0,0,.22,2.77,2.77,0,0,0,.18,1A1.23,1.23,0,0,0,13,11.52Z" />
          <path data-classname="cls-100" d="M13.54,9.45h0l0-.14a3,3,0,0,0-.38.24Z" />
        </g>
      </g>
    </svg>
  )
}